import { apiServer } from '../../env';

export const getUser = async (token) => {
  try {
    const auth = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const userInfoResponse = await fetch(`${apiServer()}/users`, auth);
    if (userInfoResponse.status !== 200) {
      return userInfoResponse;
    } else {
      const userInfo = await userInfoResponse.json();
      return { ...userInfo };
    }
  } catch (e) {
    return Promise.reject(e);
  }
};
