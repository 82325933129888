import React, {useState} from "react";
import {FormModalWithOptions} from "@platform/cj-platform-navigation";
import {FormPageBlock, FormPageLayout,} from "@cjdev-internal/visual-stack-x/FormPageLayout";
import {Stack} from "@cjdev-internal/visual-stack-x/components/Stack";
import {Field} from "@cjdev-internal/visual-stack-x/Field";
import {Input} from "@cjdev-internal/visual-stack-x/Input";
import {ToggleSwitch} from "@cjdev-internal/visual-stack-x/components/ToggleSwitch";
import {ADVERTISERS} from "../../paths";

export const CreateAdvertiserPrime = ({
                                          history,
                                          navigate,
                                          navigateTo,
                                          usePlatformNav,
                                          submitAdvertiser,
                                      }) => {
    const [isOpen, setIsOpen] = useState(true);
    const INITIAL_STATE = {
        cid: "",
        name: "",
        prefix: "",
        enabled: true,
    };
    const [advertiser, setAdvertiser] = useState(INITIAL_STATE);
    const platformNav = usePlatformNav();

    const handleSubmit = async () => {
        const advertiserObject = {
            advertiser,
            websiteIds: [],
        };
        await submitAdvertiser(advertiserObject);
        closeModal();
    };

    const handleChange = (attr, value) => {
        setAdvertiser((prev) => ({
            ...prev,
            [attr]: value,
        }));
    };

    const closeModal = () => {
        setIsOpen(false);
        navigateTo(ADVERTISERS, navigate, history, platformNav);
    };

    const Content = (
        <FormPageLayout verticalPad>
            <FormPageBlock>
                <Stack gap="medium">
                    <Field label="Advertiser CID" data-testid="advertiser-id">
                        <Input
                            autoComplete="off"
                            data-testid="cid"
                            value={advertiser.cid}
                            onChange={(e) => handleChange("cid", e.target.value)}
                        />
                    </Field>
                    <Field label="Advertiser Name" data-testid="advertisers-name">
                        <Input
                            autoComplete="off"
                            data-testid="name"
                            value={advertiser.name}
                            onChange={(e) => handleChange("name", e.target.value)}
                        />
                    </Field>
                    <Field label="Compliance Violation Prefix" data-testid="prefix">
                        <Input
                            autoComplete="off"
                            data-testid="prefix"
                            value={advertiser.prefix}
                            onChange={(e) => handleChange("prefix", e.target.value)}
                        />
                    </Field>

                    <ToggleSwitch
                        data-testid="enable"
                        active={advertiser.enabled}
                        onChange={(e) => handleChange("enabled", e)}
                        label="Enable"
                    />
                </Stack>
            </FormPageBlock>
        </FormPageLayout>
    );

    return (
        <FormModalWithOptions
            id={"editAdvertiser"}
            isOpen={isOpen}
            onRequestClose={closeModal}
            onSubmit={handleSubmit}
            submitText="Submit"
            title={"Create Advertiser"}
            gap="medium"
        >
            {Content}
        </FormModalWithOptions>
    );
};
