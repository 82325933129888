import {AppLayout} from '../App';
import IssueDetailView from './components/IssueDetailView';
import {getUser, isEmployee, isPublisher} from '../../shared/userStorage';
import {usePlatformNav} from '../../appRunner';
import {history, withParams} from '../../router';
import IssueDetailLoading from './components/IssueDetailLoading';
import fetchIssue from './services';
import useLoadIssue from './hooks/useLoadIssue';
import useEventListener from '../../shared/hooks/useEventListener';
import {useCallback, useEffect, useState} from 'react';
import IssueDetailError from './components/IssueDetailError';
import {toBackList} from './helpers/dataHelpers';
import EmailContent from "./components/EmailContent";
import {EmailNotificationModal} from "./components/EmailNotificationModal";
import {useModal} from '@cjdev-internal/visual-stack-x/components/Modal';
import {useToast} from "@cjdev-internal/visual-stack-x/Toast";
import ActionsDetailView from './components/ActionsDetailView';

export const IssuesDetail = ({
                                 fetchIssue,
                                 Loading,
                                 DetailView,
                                 useLoadIssue,
                                 useEventListener,
                                 usePlatformNav,
                                 useModal,
                                 ErrorView,
                                 complianceViolationId,
                                 onBackButtonClick,
                                 EmailContent,
                                 EmailNotificationModal, useToast
                             }) => {
    const IssueDetailTitle = `Compliance Violations / Violation Details`;
    const {status, issue, error, reload} = useLoadIssue(complianceViolationId, fetchIssue);
    usePlatformNav();
    const [notifyModal, setNotifyModal] = useState({notify: false});
    const [mount, vsxOpenModal, vsxCloseModal] = useModal();
    useEventListener('UPDATE_ISSUE_VIEW', reload);
    const [toastMount, show] = useToast();

    const onContactNotification = useCallback((event) => {
        setNotifyModal({
            notify: true,
            component: (publisherName, issueCategory, issueDescription, issue) => {
                vsxOpenModal(<EmailNotificationModal
                    issue={issue}
                    publisherContact={event.detail.publisherContact}
                    EmailContent={EmailContent}
                    vsxCloseModal={vsxCloseModal}
                    networkQualityContact={event.detail.networkQualityContact}
                    advertiserContact={event.detail.advertiserContact}
                    additionalNotes={event.detail.additionalNotes}
                    publisherName={publisherName}
                    issueCategory={issueCategory}
                    issueDescription={issueDescription}
                    show={show}
                    initialRetryCount={0}
                />)
            }
        })
    }, []);
    useEventListener('OPEN_CONTACT_NOTIFICATION', onContactNotification);

    useEffect(() => {
        if (status === 'complete') {
            const loadedIsseueEvent = new CustomEvent('ISSUE_LOADED', {detail: issue})
            document.dispatchEvent(loadedIsseueEvent);
        }
        const publisherName = issue?.publisher?.name;
        const issueCategory = issue?.violationTypeMapping?.category;
        const issueDescription = issue?.violationTypeMapping?.description;
        if (notifyModal.notify && status === 'complete') {
            notifyModal.component(publisherName, issueCategory, issueDescription, issue);
            setNotifyModal({notify: false});
        }
    }, [status, issue, notifyModal]);

    return (<>
        <AppLayout pageHeaderTitle={IssueDetailTitle} slidingVsx={true} onBackButtonClick={onBackButtonClick}>
            {toastMount}
            {mount}
            {status === 'loading' && <Loading/>}
            {status === 'complete' && (<>
                <DetailView issue={issue}/>
            </>)}
            {status === 'error' && <ErrorView error={error}/>}
        </AppLayout>
    </>);
};

const IssuesDetailDefault = ({user, params}) => {
    if (!user) {
        user = getUser().user
    }
    const {complianceViolationId} = params;
    const _isPublisher = isPublisher(user);
    const _isEmployee = isEmployee(user);
    const Loading = () => <IssueDetailLoading/>;
    const ManageButton = () => usePlatformNav() && !_isPublisher &&
        <ActionsDetailView complianceViolationId={complianceViolationId}/>;
    const DetailView = props => (
        <IssueDetailView {...props} title={complianceViolationId} isPublisher={_isPublisher}
                         isEmployee={_isEmployee} ManageButton={ManageButton}/>);
    const ErrorView = ({error}) => <IssueDetailError text={error}/>;
    return (<IssuesDetail
        complianceViolationId={complianceViolationId}
        fetchIssue={fetchIssue}
        Loading={Loading}
        DetailView={DetailView}
        ErrorView={ErrorView}
        usePlatformNav={usePlatformNav}
        useEventListener={useEventListener}
        useLoadIssue={useLoadIssue}
        useModal={useModal}
        onBackButtonClick={() => toBackList(null, history, false)}
        EmailContent={EmailContent}
        EmailNotificationModal={EmailNotificationModal}
        useToast={useToast}
    />);
};

export default withParams(IssuesDetailDefault);
