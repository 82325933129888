import React, {useEffect, useState} from "react";
import {Input} from "@cjdev-internal/visual-stack-x/Input";
import {Field} from "@cjdev-internal/visual-stack-x/Field";
import {FormPageBlock, FormPageLayout,} from "@cjdev-internal/visual-stack-x/FormPageLayout";
import {ADVERTISERS} from "../../paths";
import {FormModalWithOptions} from "@platform/cj-platform-navigation";
import {ToggleSwitch} from "@cjdev-internal/visual-stack-x/components/ToggleSwitch";
import {Stack} from "@cjdev-internal/visual-stack-x/components/Stack";

export const EditAdvertiser = ({
                                   history,
                                   fetchAdvertiserById,
                                   navigateTo,
                                   usePlatformNav,
                                   params,
                                   navigate,
                                   submitAdvertiser,
                               }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [advertiser, setAdvertiser] = useState({prefix: ""});
    const platformNav = usePlatformNav();

    const hanldeSubmit = async () => {
        const advertiserObject = {
            advertiser,
            websiteIds: [],
        };

        await submitAdvertiser(advertiser.cid, advertiserObject);
        closeModal();
    };

    const handleChange = (attr, value) => {
        setAdvertiser((prev) => ({
            ...prev,
            [attr]: value,
        }));
    };

    const closeModal = () => {
        setIsOpen(false);
        navigateTo(ADVERTISERS, navigate, history, platformNav);
    };

    const loadAdvertiser = async () => {
        const response = await fetchAdvertiserById(params.advertiserId);
        setAdvertiser(response.advertiser);
    };

    useEffect(() => {
        loadAdvertiser();
    }, []);

    const Content = (
        <FormPageLayout verticalPad>
            <FormPageBlock>
                <Stack gap="medium">
                    <Field label="Advertiser CID" data-testid="advertiser-id">
                        <Input data-testid="cid" value={advertiser.cid} readOnly/>
                    </Field>
                    <Field label="Advertiser Name" data-testid="advertiser-name">
                        <Input
                            autoComplete="off"
                            data-testid="name"
                            value={advertiser.name}
                            onChange={(e) => handleChange("name", e.target.value)}
                        />
                    </Field>
                    <Field label="Compliance Violation Prefix" data-testid="prefix">
                        <Input
                            autoComplete="off"
                            data-testid="prefix"
                            value={advertiser.prefix}
                            onChange={(e) => handleChange("prefix", e.target.value)}
                        />
                    </Field>

                    <ToggleSwitch
                        active={advertiser.enabled}
                        onChange={(e) => handleChange("enabled", e)}
                        label="Enable"
                    />
                </Stack>
            </FormPageBlock>
        </FormPageLayout>
    );

    return (<FormModalWithOptions
        id={"editAdvertiser"}
        isOpen={isOpen}
        onRequestClose={closeModal}
        onSubmit={hanldeSubmit}
        submitText="Submit"
        title={"Edit Advertiser"}
        gap="medium"
    >
        {Content}
    </FormModalWithOptions>)
};
