import React from 'react';
import styled from 'styled-components';
import DashboardDatePicker from './DashboardDatePicker';
import DashboardViewController from './DashboardViewController';
import { useNavigate, useSearchParams } from 'react-router-dom'
const DashboardHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1d1d2;
  padding: 8px;
  width: 100%;
`;


export const DashboardHeader = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const dashboardViewController = new DashboardViewController(navigate, searchParams.toString());
  return (
    <DashboardHeaderDiv>
      <DashboardDatePicker onSubmit={dashboardViewController.changeDateRange} />
      <></>
    </DashboardHeaderDiv>
  );
};
