import React, {useEffect, useState} from 'react';
import {AppLayout} from '../App';
import {Container, Gauge} from '../../components/Dashboard/gauge';
import {connect} from 'react-redux';
import {Panel} from '@cjdev-internal/visual-stack-x/Panel';
import * as fromViolationDashboardActions from '../../domain/ViolationDashboard/actions';
import {
    selectHighSeverityValues,
    selectIsPublisher,
    selectIsUserLoading,
    selectViolationsDashboardOverview
} from '../../reducer';
import * as R from 'ramda';
import RiskIdentificationTable from './RiskIdentificationTable';
import RiskIdentificationChart from './RiskIdentificationChart';
import ViolationTrendChart from './ViolationTrendChart';
import './style.css';
import styled from 'styled-components';
import {useSearchParams} from 'react-router-dom'
import {useUser} from '@platform/cj-platform-navigation'
import Spinner from "../../components/Spinner";

const BottomPanelWrapper = styled.div`
    margin-bottom: 115px;
`;

const getTotalViolationsCount = R.view(R.lensPath(['totalNumberOfViolations', 'count']));
const getPreviousViolationsCount = R.view(
    R.lensPath(['totalNumberOfViolations', 'previousPeriodCount'])
);

const getTotalHighSeverityViolationsCount = R.view(
    R.lensPath(['totalNumberOfHighSeverityViolations', 'count'])
);

const getTotalErrorPoints = R.view(R.lensPath(['totalErrorPoints', 'count']));

const getPreviousHighSeverityViolationsCount = R.view(
    R.lensPath(['totalNumberOfHighSeverityViolations', 'previousPeriodCount'])
);

const getAverageResolutionTime = R.view(R.lensPath(['averageResolutionTime', 'durationInHours']));

const getPreviousAverageResolutionTime = R.view(
    R.lensPath(['averageResolutionTime', 'previousDurationInHours'])
);

const getPreviousTotalErrorPoints = R.view(R.lensPath(['totalErrorPoints', 'previousCount']));

const mapDispatchToProps = {
    ...fromViolationDashboardActions
};

const mapStateToProps = state => ({
    overviews: selectViolationsDashboardOverview(state),
    isPublisher: selectIsPublisher(state),
    isUserLoading: selectIsUserLoading(state),
    highSeverityValues: selectHighSeverityValues(state)
});

const OverViews = ({
                       violationCount,
                       previousViolationsCount,
                       totalHighSeverityViolationCount,
                       previousHighSeverityViolationsCount,
                       highSeverityValue,
                       totalErrorPoints,
                       previousTotalErrorPoints,
                       averageResolutionTime,
                       averageResolutionTimeDelta,
                       previousAverageResolutionTime,
                       isPublisher
                   }) => {

    const [analytics, setAnalytics] = useState({
        previousViolationsCountState: 'Loading...',
        trendValueState: 'Loading...',
        totalPreviousHighSeverityViolationsCountState: 'Loading...',
        trendValueHighSeverityState: 'Loading...',
        previousTotalErrorPointsState: 'Loading...',
        trendValueErrorPointsState: 'Loading...'
    });

    useEffect(() => {
        if (previousViolationsCount !== undefined && violationCount !== undefined ||
            previousHighSeverityViolationsCount !== undefined && totalHighSeverityViolationCount !== undefined ||
            previousTotalErrorPoints !== undefined && totalErrorPoints !== undefined) {
            setAnalytics(prevState => ({
                ...prevState,
                totalPreviousHighSeverityViolationsCountState: previousHighSeverityViolationsCount,
                trendValueHighSeverityState: totalHighSeverityViolationCount - previousHighSeverityViolationsCount,
                previousViolationsCountState: previousViolationsCount,
                trendValueState: violationCount - previousViolationsCount,
                previousTotalErrorPointsState: previousTotalErrorPoints,
                trendValueErrorPointsState: totalErrorPoints - previousTotalErrorPoints
            }));
        }
    }, [previousViolationsCount, violationCount, previousHighSeverityViolationsCount, totalHighSeverityViolationCount, previousTotalErrorPoints, totalErrorPoints]);


    return (
        <Container>
            <div data-testid="total-violations-gauge">
                <Gauge
                    title="Total Violations"
                    data-testid="total-violations-gauge"
                    value={violationCount ?? <Spinner data-testid='spinner'/>}
                    trendValue={analytics.trendValueState}
                    previousPeriod={analytics.previousViolationsCountState}
                />
            </div>
            {!isPublisher && (
                <div data-testid="high-severity-gauge">
                    <Gauge
                        title="High Severity Violations"
                        value={totalHighSeverityViolationCount ?? <Spinner data-testid='spinner'/>}
                        trendValue={analytics.trendValueHighSeverityState}
                        previousPeriod={analytics.totalPreviousHighSeverityViolationsCountState}
                        filters={{severity: JSON.stringify(highSeverityValue)}}
                    />
                </div>
            )}
            {!isPublisher && (
                <div data-testid="total-error-points">
                    <Gauge
                        title="Total Points"
                        value={totalErrorPoints ?? <Spinner data-testid='spinner'/>}
                        trendValue={analytics.trendValueErrorPointsState}
                        previousPeriod={analytics.previousTotalErrorPointsState}
                    />
                </div>
            )}
            <div data-testid="average-resolution-time-gauge">
                <Gauge
                    title="Average Resolution Time"
                    unit="hrs"
                    trendUnit="hrs"
                    value={(averageResolutionTime || 0).toFixed(2)}
                    trendValue={averageResolutionTimeDelta.toFixed(2)}
                    previousPeriod={(previousAverageResolutionTime || 0).toFixed(2)}
                />
            </div>
        </Container>
    );
};

const Dashboard__ = ({
                         overviews,
                         fetchDashboardOverviews,
                         isUserLoading,
                         highSeverityValues
                     }) => {
    const user = useUser()

    const isPublisher = user.currentCompany.type === "publisher"
    const [searchParams] = useSearchParams()
    const queryString = searchParams.toString()

    useEffect(() => {
        fetchDashboardOverviews(queryString);
    }, [queryString]);

    const violationCount = getTotalViolationsCount(overviews);
    const totalHighSeverityViolationCount = getTotalHighSeverityViolationsCount(overviews);
    const averageResolutionTime = getAverageResolutionTime(overviews);
    const totalErrorPoints = getTotalErrorPoints(overviews);

    const previousViolationsCount = getPreviousViolationsCount(overviews);
    const previousHighSeverityViolationsCount = getPreviousHighSeverityViolationsCount(overviews);
    const previousAverageResolutionTime = getPreviousAverageResolutionTime(overviews);
    const previousTotalErrorPoints = getPreviousTotalErrorPoints(overviews);
    const averageResolutionTimeDelta = averageResolutionTime - previousAverageResolutionTime || 0;


    return (
        <AppLayout
            pageHeaderTitle="Compliance Overview"
        >
            {!isUserLoading && (
                <div>
                    <OverViews
                        violationCount={violationCount}
                        totalHighSeverityViolationCount={totalHighSeverityViolationCount}
                        averageResolutionTime={averageResolutionTime}
                        totalErrorPoints={totalErrorPoints}
                        previousViolationsCount={previousViolationsCount}
                        previousHighSeverityViolationsCount={previousHighSeverityViolationsCount}
                        previousAverageResolutionTime={previousAverageResolutionTime}
                        previousTotalErrorPoints={previousTotalErrorPoints}
                        averageResolutionTimeDelta={averageResolutionTimeDelta}
                        isPublisher={isPublisher}
                        highSeverityValue={highSeverityValues}
                    />
                    <Panel>
                        <ViolationTrendChart/>
                    </Panel>
                    <BottomPanelWrapper>
                        <Panel>
                            <RiskIdentificationChart/>
                            <RiskIdentificationTable/>
                        </Panel>
                    </BottomPanelWrapper>
                </div>
            )}
        </AppLayout>
    );
}

export const Dashboard_ = connect(mapStateToProps, mapDispatchToProps)(Dashboard__);
