import R from 'ramda';

export const gitHash = process.env.REACT_APP_GIT_HASH;
export const environment = process.env.REACT_APP_CAS_ENV;
export const envString = environment === 'beta' ? '' : ` - (${environment})`;

const envMap = {
  local: 'dev',
  dev: 'dev',
  beta: 'beta',
  demo: 'demo'
};

export const frontEndServer = (envars = process.env) => {
  let casEnv = R.defaultTo('dev', envMap[envars.REACT_APP_CAS_ENV]);
  return `http://cas-frontend-${casEnv}.s3-website.us-east-2.amazonaws.com`;
};

export const apiServer = (envars = process.env) => {
  let casEnv = R.defaultTo('dev', envMap[envars.REACT_APP_CAS_ENV]);
  const url =
    casEnv === 'beta'
        ? 'https://api.compliance.cj.com'
      : `https://api${casEnv}.automation.cjpowered.com`;
  return url;
};

export const membersApiServer = (envars = process.env) => {
  let casEnv = R.defaultTo('dev', envMap[envars.REACT_APP_CAS_ENV]);
  const url =
      casEnv === 'beta'
          ? 'https://members.cj.com/affapi'
          : 'https://member104.lab.cj.com/affapi';
  return url;
};

